import {SbxCoreService, SbxSessionService} from 'sbx-axios';

let sbxCoreService: SbxCoreService;
let sbxSessionService: SbxSessionService;
let validateCS: string;
let testMode: boolean;

export function initialize(core: SbxCoreService, session: SbxSessionService, validate: string, test: boolean = false) {
  sbxCoreService = core;
  sbxSessionService = session;
  validateCS = validate;
  testMode = test;
}

export class AuthConfig {
  private static instance: AuthConfig;
  public sbxCoreService: SbxCoreService;
  public sbxSessionService: SbxSessionService;
  public testMode: boolean;
  public validateCS: string;

  private constructor() {
    this.sbxCoreService = sbxCoreService;
    this.sbxSessionService = sbxSessionService;
    this.validateCS = validateCS;
    this.testMode = testMode;
  }

  public static getInstance(): AuthConfig {
    if (!AuthConfig.instance) {
      AuthConfig.instance = new AuthConfig();
    }
    return AuthConfig.instance;
  }
}
